import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function Solutions() {
  const height = 100;
  return (
    <div className="solutions">
      <div className="solutions-icons">
        <div className="solutions-icons__card">
          <div className="solutions-icons__card-content">
            <StaticImage
              src="../../images/inicial/news/money.svg"
              alt="Foto de uma nota"
              placeholder="tracedSVG"
              height={height}
            />
            <p>Mais barato que o valor da sua hora</p>
          </div>
        </div>
        <div className="solutions-icons__card">
          <div className="solutions-icons__card-content">
            <StaticImage
              src="../../images/inicial/news/compliance.svg"
              alt="Foto de compliance"
              placeholder="tracedSVG"
              height={height}
            />
            <p>Compliance com as normas do IFRS 16 / CPC 06</p>
          </div>
        </div>
        <div className="solutions-icons__card">
          <div className="solutions-icons__card-content">
            <StaticImage
              src="../../images/inicial/news/clock.svg"
              alt="Foto de um relógio"
              placeholder="tracedSVG"
              height={height}
            />
            <p>Fácil implantação e migração</p>
          </div>
        </div>
        <div className="solutions-icons__card">
          <div className="solutions-icons__card-content">
            <StaticImage
              src="../../images/inicial/news/releases.svg"
              alt="Foto de lançamentos"
              placeholder="tracedSVG"
              height={height}
            />
            <p>Lançamentos contábeis automáticos</p>
          </div>
        </div>
      </div>
    </div>
  );
}
