import React from "react";
import Button from "../../common/Button";
import { Link as Scroll } from "react-scroll";
import { StaticImage } from "gatsby-plugin-image";
import SectionContainer from "../../common/SectionContainer";

export default function InitialHeader() {
  return (
    <div className="initial-header">
      <SectionContainer backgroundColor="#f3f3f3">
        <div className="section-container__content">
          <StaticImage
            src="../../images/IFRS16LOGO.png"
            alt="Gestão Contábil"
            placeholder="tracedSVG"
            className="gestao-contabil"
          />
          <h2>
            Faça em <b>03 minutos</b> o que você levaria 03 horas
          </h2>
          <p>
            Não perca mais tempo controlando seus contratos de arrendamento no
            <b> Excel!</b>
          </p>
          <Scroll
            to="sobre"
            activeClass="active"
            smooth={true}
            duration={700}
            offset={-50}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              width="75%"
              backgroundColor="green"
              className="call-consulting"
            >
              Falar com consultor
            </Button>
          </Scroll>
        </div>
        <StaticImage
          src="../../images/notebook.png"
          alt="Gestão Contábil"
          placeholder="tracedSVG"
          className="notebook-image"
        />
      </SectionContainer>
    </div>
  );
}
