import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionContainer from "../../common/SectionContainer";

export default function About() {
  return (
    <div className="about">
      <SectionContainer backgroundColor="#eeeeee">
        <div className="about__content">
          <h2>Funcionalidades</h2>
          <ul>
            <li>Projeção de despesas de juros e depreciação</li>
            <li>Remensuração mensal (preços)</li>
            <li>Controle dos saldos de ativo e passivo</li>
            <li>Nota explicativa do Direito de uso e Arrendamento</li>
            <li>Controle por centro de custo</li>
            <li>Contabilização por contrato</li>
          </ul>
        </div>
        <StaticImage
          src="../../images/inicial/sobre.svg"
          alt=""
          placeholder="tracedSVG"
          className="gestao-contabil"
          objectFit="contain"
        />
      </SectionContainer>
    </div>
  );
}
