import * as React from "react";
import Navbar from "../common/Navbar";
import InitialHeader from "../components/InitialHeader";
import Solutions from "../components/Solutions";
import BePartner from "../components/BePartner";
import Sobre from "../components/About";
import Footer from "../common/Footer";
import Costomers from "../components/Costomers";
import ContactForm from "../components/ContactForm";
import Main from "../common/Main";
import "./main.scss";
import PlansTable from "../components/PlansTable";

export default function Index() {
  return (
    <Main>
      <Navbar backgroundColor="#f3f3f3" />
      <InitialHeader />
      <Solutions />
      <Sobre />
      <BePartner />
      <Costomers />
      <PlansTable />
      <ContactForm />
      <Footer />
    </Main>
  );
}
