import React from "react";

export default function SectionContainer(props) {
  return (
    <div
      className={`section-container ${props.className}`}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {props.children}
    </div>
  );
}
