import React from "react";
import InputMask from "react-input-mask";
import SectionContainer from "../../common/SectionContainer";
import Button from "../../common/Button";
import { StaticImage } from "gatsby-plugin-image";

export default function FormularioContato() {
  return (
    <section className="formulario-contato">
      <SectionContainer>
        <div id="contato">
          <h2>Fale com um consultor</h2>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            action="/obrigado"
            subject="Contato pelo formulário do site ifrsys.com.br"
          >
            <input name="form-name" type="hidden" value="contact" />
            <label htmlFor="nome">Nome:</label>
            <input name="nome" type="text" id="nome" />
            <label htmlFor="phone">Telefone:</label>
            <InputMask name="telefone" mask="(99) 99999-9999" id="phone" />
            <label htmlFor="email">Email:</label>
            <input name="email" type="email" id="email" />
            <label htmlFor="enterprise">Empresa:</label>
            <input
              name="empresa-segmento"
              type="text"
              id="enterprise"
              style={{ marginBottom: "40px" }}
            />
            <Button
              type="submit"
              backgroundColor="dark-blue"
              width="50%"
              className="submit-button"
            >
              Enviar mensagem
            </Button>
          </form>
        </div>
        <StaticImage
          src="../../images/inicial/news/notebooks.png"
          alt=""
          className="formulario-imagem"
        />
      </SectionContainer>
    </section>
  );
}
