import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Button from "../../common/Button";

export default function PlansTable() {
  const CheckTrue = () => (
    <StaticImage
      src="../../images/inicial/news/CheckCircleFill.svg"
      alt="Imagem correto"
    />
  );
  const CheckFalse = () => (
    <StaticImage
      src="../../images/inicial/news/XCircleFill.svg"
      alt="Imagem errado"
    />
  );

  const tableData = [
    {
      key: 1,
      name: "Cálculo completo",
      basic: <CheckTrue />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 2,
      name: "Correções e indexações",
      basic: <CheckTrue />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 3,
      name: "Contabilização mensal",
      basic: <CheckTrue />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 4,
      name: "Avisos e controles de contratos",
      basic: <CheckTrue />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 5,
      name: "Dashboard",
      basic: <CheckTrue />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 6,
      name: "Projeção de despesas",
      basic: <CheckFalse />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 7,
      name: "Notas explicativas",
      basic: <CheckFalse />,
      advanced: <CheckTrue />,
      premium: <CheckTrue />,
    },
    {
      key: 8,
      name: "Volume contratos",
      basic: <p>Máximo: 10</p>,
      advanced: <p>Máximo: 100</p>,
      premium: <p>Ilimitado</p>,
    },
  ];
  return (
    <div className="plans-table">
      <h2>Conheça nossos planos</h2>
      <table>
        <thead>
          <tr>
            <th> </th>
            <th>
              <h3>Basíco</h3>
              <p>R$ 700,00/ mês</p>
            </th>
            <th>
              <h3>Avançado</h3>
              <p>R$ 1.200,00/ mês</p>
            </th>
            <th>
              <h3>Avançado</h3>
              <p>R$ 2.400,00/ mês</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item.key}>
              <td style={{ textAlign: "initial" }}>{item.name}</td>
              <td>{item.basic}</td>
              <td>{item.advanced}</td>
              <td>{item.premium}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        backgroundColor="green"
        width="20%"
        className="plans-table__button"
      >
        Falar com consultor
      </Button>
    </div>
  );
}
