import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticImage } from "gatsby-plugin-image";
import { useMediaQuery } from "react-responsive";

export default function Costumers() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const settings_mob = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const mobile = useMediaQuery({ query: "(max-width:880px)" });
  const desktop = useMediaQuery({ query: "(min-width:881px)" });
  const slides = [
    <div className="slide__content" key="slide1">
      <StaticImage
        src="../../images/inicial/news/aster-maquinas.png"
        alt="Vale do Ribeira"
        className="img"
        objectFit="contain"
      />
    </div>,
    <div className="slide__content" key="slide2">
      <StaticImage
        src="../../images/inicial/news/agro-amazonia.png"
        alt="Fato Urbanismo"
        className="img"
        objectFit="contain"
      />
    </div>,
    <div className="slide__content" key="slide3">
      <StaticImage
        src="../../images/inicial/news/usina-coprodia.png"
        alt="Agro Amazônia"
        className="img"
        objectFit="contain"
      />
    </div>,
  ];

  return (
    <div className="slide">
      <div className="slide__title">
        <h2>Empresas que decidiram agilizar o trabalho</h2>
      </div>
      {mobile && (
        <Slider {...settings_mob} className="overflow-hidden">
          {slides.map((slide) => slide)}
        </Slider>
      )}
      {desktop && (
        <div className="desktop-client-content">
          {slides.map((slide) => slide)}
        </div>
      )}
    </div>
  );
}
