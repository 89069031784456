import React from "react";

export default function Button(props) {
  return (
    <button
      className={`default-button-${props.backgroundColor} default-button ${props.className}`}
      style={{
        display: props.hidden ? "none" : "block",
        width: props.width,
        color: props.color,
      }}
      type={props.type}
    >
      {props.children}
    </button>
  );
}
