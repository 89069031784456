import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../common/Button";
import SectionContainer from "../../common/SectionContainer";

export default function BePartner() {
  return (
    <div className="be-partner">
      <SectionContainer>
        <StaticImage
          src="../../images/inicial/parceiro.svg"
          alt=""
          placeholder="tracedSVG"
          className="gestao-contabil"
        />
        <div className="be-partner__text">
          <p>
            IFRSys é uma plataforma online de gestão de normas internacionais de
            contabilidade (IFRS) e traz o módulo de gestão e cálculo de
            contratos de locação e arrendamento - <b>IFRS 16 / CPC 06 (R2).</b>
          </p>
          <p>
            No módulo <b>IFRS 16</b> você encontra tudo que precisa para
            <b> otimizar a rotina da área contábil</b>, trazendo mais eficiência
            aos fechamentos e maior compliance, isso porque o sistema faz desde
            o cálculo de valor presente, remensurações até a contabilização,
            tudo de <b>forma integrada</b>.
          </p>
          <p>
            <b style={{ color: "#FCD902" }}>
              Cadastre centenas de contratos de arrendamento em questão de
              segundos
            </b>
            , elimine planilhas e trabalhos manuais e ganhe horas de trabalho da
            equipe contábil para focar no negócio e demais atividades.
          </p>
          <Button
            width="100%"
            backgroundColor="yellow"
            className="button-bepartner"
          >
            Solicitar demonstração
          </Button>
        </div>
      </SectionContainer>
    </div>
  );
}
